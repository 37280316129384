// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-forgotpass-jsx": () => import("./../src/pages/forgotpass.jsx" /* webpackChunkName: "component---src-pages-forgotpass-jsx" */),
  "component---src-pages-forgotuser-jsx": () => import("./../src/pages/forgotuser.jsx" /* webpackChunkName: "component---src-pages-forgotuser-jsx" */),
  "component---src-pages-forgotuserwrongemail-jsx": () => import("./../src/pages/forgotuserwrongemail.jsx" /* webpackChunkName: "component---src-pages-forgotuserwrongemail-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-loading-jsx": () => import("./../src/pages/loading.jsx" /* webpackChunkName: "component---src-pages-loading-jsx" */),
  "component---src-pages-loginsubexpired-jsx": () => import("./../src/pages/loginsubexpired.jsx" /* webpackChunkName: "component---src-pages-loginsubexpired-jsx" */),
  "component---src-pages-resetpass-jsx": () => import("./../src/pages/resetpass.jsx" /* webpackChunkName: "component---src-pages-resetpass-jsx" */),
  "component---src-pages-restorepassword-jsx": () => import("./../src/pages/restorepassword.jsx" /* webpackChunkName: "component---src-pages-restorepassword-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-pages-usernamerestore-jsx": () => import("./../src/pages/usernamerestore.jsx" /* webpackChunkName: "component---src-pages-usernamerestore-jsx" */),
  "component---src-pages-wrongcredentials-jsx": () => import("./../src/pages/wrongcredentials.jsx" /* webpackChunkName: "component---src-pages-wrongcredentials-jsx" */)
}

